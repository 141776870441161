<template>
  <div class="bg-gray-200">
    <main class="overflow-hidden">
      <!-- Header -->
      <div class="bg-warm-gray-50">
        <div class="py-24 lg:py-32">
          <div
            class="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8"
          >
            <h1
              class="text-6xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl"
              data-aos="fade-up"
            >
              Get in touch with <span class="text-G">Direct Debs</span>.
            </h1>
          </div>
        </div>
      </div>

      <!-- Contact section -->
      <section
        class="relative bg-gray-200 pb-36"
        aria-labelledby="contact-heading"
      >
        <div class="absolute w-full h-1/2 bg-warm-gray-50" aria-hidden="true" />
        <!-- Decorative dot pattern -->

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="relative bg-white rounded-md shadow-md">
            <h2 id="contact-heading" class="sr-only">Contact us</h2>

            <div class="grid grid-cols-1 lg:grid-cols-3">
              <!-- Contact information -->
              <div
                class="relative overflow-hidden py-10 px-6 bg-gradient-to-b from-teal-500 to-teal-600 sm:px-10 xl:p-12"
              >
                <!-- Decorative angle backgrounds -->

                <h2 class="text-4xl font-extrabold text-G">
                  Contact information.
                </h2>
                <p class="mt-6 text-base text-teal-50 max-w-3xl">
                  {{ contact.Contact_Info_Paragraph }}
                </p>
                <dl class="mt-8 space-y-6">
                  <dt><span class="sr-only">Email</span></dt>
                  <dd class="flex text-base text-teal-50">
                    <span>bookings@directdebs.ie</span>
                  </dd>
                </dl>
                <ul role="list" class="mt-8 flex space-x-12">
                  <li>
                    <a class="text-teal-200 hover:text-teal-100" href="#">
                      <svg
                        class="w-7 h-7"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a class="text-teal-200 hover:text-teal-100" href="#">
                      <svg
                        class="w-7 h-7"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>

              <!-- Contact form -->
              <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                <h2 class="text-4xl font-extrabold text-G">
                  Send us a message.
                </h2>
                <form
                  @submit.prevent="handleSubmit"
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                >
                  <div>
                    <label
                      for="first-name"
                      class="block text-sm font-medium text-warm-gray-900"
                      >First name</label
                    >
                    <div class="mt-1">
                      <input
                        v-model="form.first_name"
                        type="text"
                        name="first_name"
                        id="first_name"
                        autocomplete="given-name"
                        class="py-3 bg-gray-200 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-G focus:border-G border-G rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="last_name"
                      class="block text-sm font-medium text-warm-gray-900"
                      >Last name</label
                    >
                    <div class="mt-1">
                      <input
                        v-model="form.last_name"
                        type="text"
                        name="last_name"
                        id="last_name"
                        autocomplete="family-name"
                        class="py-3 bg-gray-200 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-G focus:border-G border-G rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="email"
                      class="block text-sm font-medium text-warm-gray-900"
                      >Email</label
                    >
                    <div class="mt-1">
                      <input
                        v-model="form.email"
                        id="email"
                        name="email"
                        type="email"
                        autocomplete="email"
                        class="py-3 bg-gray-200 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-G focus:border-G border-G rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <div class="flex justify-between">
                      <label
                        for="phone"
                        class="block text-sm font-medium text-warm-gray-900"
                        >Phone</label
                      >
                    </div>
                    <div class="mt-1">
                      <input
                        v-model="form.phone"
                        type="text"
                        name="phone"
                        id="phone"
                        autocomplete="tel"
                        class="py-3 bg-gray-200 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-G focus:border-G border-G rounded-md"
                        aria-describedby="phone-optional"
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label
                      for="subject"
                      class="block text-sm font-medium text-warm-gray-900"
                      >School or College Name + Subject Title</label
                    >
                    <div class="mt-1">
                      <input
                        v-model="form.subject"
                        type="text"
                        name="subject"
                        id="subject"
                        class="py-3 bg-gray-200 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-G focus:border-G border-G rounded-md"
                      />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label
                      for="event_type"
                      class="block text-sm font-medium text-warm-gray-900"
                      >Event Type - Debs, Pre-debs, Graduation or Other
                      Event</label
                    >
                    <div class="mt-1">
                      <input
                        v-model="form.event_type"
                        type="text"
                        name="event_type"
                        id="subject"
                        class="py-3 bg-gray-200 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-G focus:border-G border-G rounded-md"
                      />
                    </div>
                  </div>
                  <!-- NEW FIELDS -->

                  <div class="sm:col-span-2">
                    <label
                      for="committee"
                      class="block text-sm font-medium text-warm-gray-900"
                      >Number of people on the committee</label
                    >
                    <div class="mt-1">
                      <input
                        v-model="form.committee"
                        type="text"
                        name="committee"
                        id="subject"
                        class="py-3 bg-gray-200 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-G focus:border-G border-G rounded-md"
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-2">
                    <label
                      for="attendance"
                      class="block text-sm font-medium text-warm-gray-900"
                      >Estimated number of students that will attend the
                      event</label
                    >
                    <div class="mt-1">
                      <input
                        v-model="form.attendance"
                        type="text"
                        name="attendance"
                        id="subject"
                        class="py-3 bg-gray-200 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-G focus:border-G border-G rounded-md"
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-2">
                    <label
                      for="dates"
                      class="block text-sm font-medium text-warm-gray-900"
                      >Month & year you wish to host the event</label
                    >
                    <div class="mt-1">
                      <input
                        v-model="form.dates"
                        type="text"
                        name="dates"
                        id="subject"
                        class="py-3 bg-gray-200 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-G focus:border-G border-G rounded-md"
                      />
                    </div>
                  </div>

                  <!-- NEW FIELDS -->
                  <div class="sm:col-span-2">
                    <div class="flex justify-between">
                      <label
                        for="message"
                        class="block text-sm font-medium text-warm-gray-900"
                        >Message</label
                      >
                    </div>
                    <div class="mt-1">
                      <textarea
                        v-model="form.message"
                        id="message"
                        name="message"
                        rows="4"
                        class="py-3 bg-gray-200 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-G focus:border-G border-G rounded-md"
                        aria-describedby="message-max"
                      />
                    </div>
                  </div>

                  <div>
                    <p class="hidden">
                      <label>
                        Don’t fill this out if you’re human:
                        <input name="bot-field" />
                      </label>
                    </p>
                  </div>
                  <div class="sm:col-span-2 sm:flex sm:justify-end">
                    <button
                      type="submit"
                      class="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-G hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- This example requires Tailwind CSS v2.0+ -->

      <div class="bg-gray-50">
        <div class="max-w-7xl mx-auto py-12 px-4 sm:py-36 sm:px-6 lg:px-8">
          <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
            <h2
              class="text-center text-4xl font-extrabold text-gray-900 sm:text-4xl"
            >
              Frequently asked questions.
            </h2>
            <dl class="mt-6 space-y-6 divide-y divide-gray-200">
              <Disclosure
                as="div"
                v-for="faq in faqs"
                :key="faq.question"
                class="pt-6"
                v-slot="{ open }"
              >
                <dt class="text-lg">
                  <DisclosureButton
                    class="text-left w-full flex justify-between items-start text-gray-400"
                  >
                    <h2 class="font-medium text-G">
                      {{ faq.question }}
                    </h2>
                    <span class="ml-6 h-7 flex items-center">
                      <ChevronDownIcon
                        :class="[
                          open ? '-rotate-180' : 'rotate-0',
                          'h-6 w-6 transform',
                        ]"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" class="mt-2 pr-12">
                  <p class="mt-2 block text-md font-medium text-black">
                    {{ faq.answer1 }}
                  </p>
                  <p class="mt-2 block text-md font-medium text-black">
                    {{ faq.answer2 }}
                  </p>
                </DisclosurePanel>
              </Disclosure>
            </dl>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Contact",
  data: () => ({
    form: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      subject: "",
      committee: "",
      attendance: "",
      dates: "",
      message: "",
      event_type: "",
    },
    contact: {
      Contact_Info_Paragraph: "",
    },
  }),
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    handleSubmit() {
      fetch("/", {
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: this.encode({
          "form-name": "contact",
          ...this.form,
        }),
      })
        .then(() => alert("Message successfully sent to Direct Debs!"))
        .catch((error) => console.error(error));
    },
    getContactPage() {
      let __this = this;
      axios
        .get(
          "https://api.storyblok.com/v2/cdn/stories/contact?cv=1661664501&token=VJUdfvoIDyOFIfXpRVZ08Qtt&version=published"
        )
        .then((response) => {
          let contactContent = response.data["story"]["content"];
          __this.contact = {
            Contact_Info_Paragraph: contactContent["Contact_Info_Paragraph"],
          };
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
  },
  created() {
    this.getContactPage();
  },
};
</script>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/outline";
import { defineComponent, h } from "vue";

const faqs = [
  {
    question: "What venues do Direct Debs work with?",
    answer1:
      "Direct Debs work with over 30+ Debs venues located across Ireland and multiple clubs just a short journey away for Pre-Debs, Graduations and mystery tours. We have a variety of venues that are located just 1 hour from most major cities so that you can spend more time partying and less time on a bus. ",
    answer2:
      "For further information on our venues, please contact us and request a brochure and specify what type of event you wish to organize and our events team will be glad to help you.",
  },
  {
    question: "What are the committee perks?",
    answer1:
      "Working on the organizing committee always will involve big responsibilities and this comes with privileges and perks. At Direct Debs we offer all our committee ",
    answer2:
      "FREE TICKETS | DRINK PROVIDED FOR THE COMMITTEE | AN EXCLUSIVE VIP AREA FOR YOUR COMMITTEE ONLY + MUCH MORE",
  },
  {
    question: "I lost my ticket, what should I do?",
    answer1:
      "If you have lost your ticket, notify the person you purchased the ticket from or the committee as soon as possible. The committee is responsible for the distribution and recording all ticket sales and will have a record if you purchased a ticket and your ticket number.",
    answer2:
      "If you have indeed lost your ticket, a replacement ticket can be organized.",
  },
  {
    question: "Can I get a refund?",
    answer1:
      "Unfortunately, tickets are non-refundable In most cases, there are exceptional circumstances in which exceptions are made.",
    answer2: "For further information please refer to our Terms and Conditions",
  },
  {
    question: "How do I confirm or making a booking with Direct Debs?",
    answer1:
      "Once all the details have been discussed and outlined between the organizing committee and our events team to secure and confirm your booking will a non-refundable deposit is required. ",
    answer2:
      "This deposit is to secure your transportation, tickets and date in the desired venue.",
  },
  {
    question:
      "Is there a minimum requirement needed to book an event with Direct Debs?",
    answer1:
      "Some venues do have minimum requirements, however our events team is able to cater for groups of all sizes and will be able to provide you with plenty of options to ensure that you and your attendees have a great night and a memorable one.",
  },
  {
    question: "Is it possible to make a special request for an event?",
    answer1:
      "Special requests are always welcome once they are in reason. Our events team will do everything within their ability to facilitate requests once we can, however, please note that there are some circumstances and situations when they are not possible.",
  },
  {
    question: "What services can the Direct Debs team offer you?",
    answer1:
      "The Direct Debs events team is contactable 24/7 and will work alongside you and your committee throughout the entire process of planning your event to excel beyond your expectations.",
    answer2:
      "We have an in house graphics and digital designer who will provide you with plenty of content to help you with advertising and encouraging others to purchase tickets.Our events team have over 10 years of experience in the events and hospitality industry and will provide you with plenty of support, advice and guidance on financial decisions, venues and a comprehensive run down of your event from start to finish and discuss everything you can expect and when it will happen.",
  },
  // More questions...
];
</script>
